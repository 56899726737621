.home{
    margin-top: 5%;
}

.head{
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    
    background-color: #F5F5F5	;
}

.number{
  padding-left: 30%;
  padding-right: 30%;
  width: 400px;

}

.site-logo {
  width: 149px;
  height: 34px;
  object-fit: contain;
  margin-top: 10%;
}

.group-372 {
  max-width: 412px;
  max-height: 184px;
  margin-top: 10%;
  width: 100%;
  align-self: center;
  object-fit: contain;
}

.parent{
  align-items: center;
  justify-content: center;
  align-content: center;
}

.dum-dum {
  width: 100%;
  height: 100%;
  font-family: "SFProDisplay";
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-top: 10%;
  margin-bottom: 10%;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  letter-spacing: -0.25px;
  color: #1d1d1d;
}



.code-again {
  width: 150px;
  padding-top: 5%;
  height: 21px;
  font-family: "SFProDisplay";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: #ff7907;
}

.change-phone {
  width: 197px;
  height: 21px;
  font-family: SFProDisplay;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-top: 10%;
  color: #ff7907;
}



.buttonNext{
  border-radius: 30;
  padding: 10;
  width: 130;
  margin-top: "40%";
  margin-left: "10%"
}

.btn-continue{
  border-radius: 30;
  padding: 10;
  width: 130;
  margin-top: 100%;
}

.bottom{
    margin-top:3%;
  }

  .bottomn{
    margin-top:1%;
  }

  .img-mobile{
    max-height: 200;
    margin-top: 150%;
    width: 100%;

  }

  .text-phone{
    background-color: #F0F0F0;
    padding: 10 ;
  }

@media only screen and (max-width: 768px) {

  .img-mobile{
    margin-top:1%;
  }

  .dum-dum{
    font-size: 0.75rem;
  }

  .home{
    margin-top: 10%;
  }

  .number{
    width: 200px;
  }

  .head{
    padding-top: 5%;
    padding-bottom: 5%;
  }


  .bottomn{
      margin-top:3%;
  }

  .img-mobile{
    display: none;
  }

  .btn-continue{
    margin-top: -10%;
    margin-left: 50%;
  }
      
  .text-phone{
    width: 250px;
  }
}

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .outer{
      margin-top: 0;
      min-height: 500px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .outer{
      margin-top: 0;
      min-height: 500px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .outer{
      margin-top: 0;
      min-height: 500px;

  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .outer{
      min-height: 500px;
  }
  .arrow{
    margin-top: 30px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .outer{
      min-height: 500px;
  }
  .arrow{
    margin-top: 30px;
  }
}