@font-face {
  font-family: 'SFProDisplay';
  src: url('./font/FontsFree-Net-SFProDisplay-Regular.ttf'); /* IE9 Compat Modes */
  src: url('./font/FontsFree-Net-SFProDisplay-Regular.ttf') format('ttf'), /* IE6-IE8 */
}

@font-face {
  font-family: 'SFProDisplay-Semibold';
  src: url('./font/SFProDisplay-Semibold.ttf'); /* IE9 Compat Modes */
  src: url('./font/SFProDisplay-Semibold.ttf') format('ttf'), /* IE6-IE8 */
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./font/Inter-Semi-Bold-600.otf'); /* IE9 Compat Modes */
  src: url('./font/Inter-Semi-Bold-600.otf') format('otf'), /* IE6-IE8 */
}



body {
  margin: 0;
  font-family: "SFProDisplay";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: "SFProDisplay";
}
