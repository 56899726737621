.upper {
    width: 100%;
    height: 70px;
    background-color: #f5fcde;
    color: black;
  }

  .cart-add{
    width: 148px;
    height: 32px;
    margin: 5px 5px 10px 5px;
    padding: 3px 50px 8px 50px;
    object-fit: contain;
    border-radius: 6px;
    background-color: #ff7907;
    border: 1px #ff7907;
    color: #fff;
  }

  .button-apply{
    border-radius: 30px;
    padding: 10px;
    width: 100px;
  }

    
 

   /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .main-outer{
        margin-top: 0;
        min-height: 500px;
  
    }
   
    .item-name{
      font-size: 15px;
    }

    .add-more-1{
      color: #ff7907;
      padding-left: 3%;
      padding-top: 5%;
    }

    .ipm{
      display: block;
    }

    .ipp{
      display: none;
    }

    .item-price{
      padding-left: 3.5%;
      font-size: 15px;
      font-weight: 600;
      margin-top: 5%;
    }

    .recName{
      font-size: 18px;
      font-weight: bold;
    }

    .cart-ser1{
      display: none;
    }

    .cart-ser2{
      display: block;
    }

    .header-ing{
      padding-left: 3%;
      padding-top: 3%;
    }

    .header-ing2{
      padding-left: 1%;
      padding-top: 3%;
    }

    .button-apply{
      float: left;
      margin-top: 2%;
    }

    .pc-amount{
      display: none;
    }

    .mob-amount{
      display: block;
    }
    

  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .main-outer{
        margin-top: 0;
        min-height: 500px;
    }

    .cart-ser1{
      display: none;
    }

    .add-more-1{
      color: #ff7907;
      padding-left: 3%;
      padding-top: 5%;
    }

    .recName{
      font-size: 20px;
      font-weight: bold;
    }


    .cart-ser2{
      display: block;
    }

    .ipm{
      display: block;
    }

    .ipp{
      display: none;
    }

    .item-price{
      font-size: 15px;
      font-weight: 600;
      margin-top: 5%;
      padding-left: 3.5%;
    }

    .header-ing{
      padding-left: 3%;
      padding-top: 3%;
    }

    .header-ing2{
      padding-left: 1%;
      padding-top: 3%;
    }

    .button-apply{
      float: left;
      margin-top: 2%;
    }

    .pc-amount{
      display: none;
    }

    .mob-amount{
      display: block;
    }

   
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .main-outer{
        margin-top: 0;
        min-height: 500px;
    }
    
    .recName{
      font-size: 20px;
      font-weight: bold;
    }

    .add-more-1{
      color: #ff7907;
      padding-left: 3%;
      padding-top: 5%;
    }

    .header-ing{
      padding-left: 3%;
      padding-top: 3%;
    }

    .header-ing2{
      padding-left: 1%;
      padding-top: 3%;
    }

    .ipm{
      display: block;
    }

    .ipp{
      display: none;
    }

    .item-price{
      font-size: 15px;
      font-weight: 600;
      padding-left: 3.5%;
      margin-top: 5%;
    }

    .cart-ser1{
      display: none;
    }

    .button-apply{
      float: left;
      margin-top: 2%;
    }

    .cart-ser2{
      display: block;
    }

    .pc-amount{
      display: none;
    }

    .mob-amount{
      display: block;
    }

    

  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .main-outer{
        min-height: 500px;
  
    }
    .item-name{
      font-size: 16px;
    }

    .item-occ{
      margin-left: 10;
      margin-right: 10;
      float: right;
    }

    .ico_minus {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }

    .ipm{
      display: none;
    }

    .ipp{
      display: block;
    }

    .item-price{
      float: right;
      font-weight: 600;
      font-size: 16px;
    }

   
    .cart-servings{
      margin-top: 3%;
      display: inline;
    }
    .recName{
      font-size: 20px;
      font-weight: bold;
      padding-left:5%;
    }

    .cart-ser1{
      display: block;
    }

    .cart-ser2{
      display: none;
    }

    .header-ing{
      padding-left: 5%;
    }

    .header-ing2{
      padding-left: 3%;
    }

    .arrow{
      margin-top: 30px;
  }

  .pc-amount{
    display: block;
  }

  .mob-amount{
    display: none;
  }

  .add-more-1{
    float: right;
    color: #ff7907;
  }

  .button-apply{
    float: left;
  }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .main-outer{
        min-height: 500px;
    }

    .cart-ser1{
      display: block;
    }

    .header-ing{
      padding-left: 5%;
    }

    .header-ing2{
      padding-left: 3%;
    }

    .cart-ser2{
      display: none;
    }

    .ipm{
      display: none;
    }

    .ipp{
      display: block;
    }

    .add-more-1{
      float: right;
      color: #ff7907;
    }

    .pc-amount{
      display: block;
    }

    .mob-amount{
      display: none;
    }

    .arrow{
      margin-top: 30px;
  }
  .button-apply{
    float: left;
  }
  }