

.title{
font-family: "SFProDisplay";
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.35px;
  color: #1d1d1d;
  padding-top: 10%;
}

.gps-icon{
    width: 13px;
    height: 13px;
}

.gps-text{
    opacity: 0.5;
  font-family: "SFProDisplay";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #000000;
}

.title-add{
    font-family: "SFProDisplay";
      font-size: 48px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -1.35px;
      color: #1d1d1d;
      padding-top: 10%;
    }

.add{
width: 212px;
  height: 21px;
  font-family: "SFProDisplay";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;

  line-height: normal;
  letter-spacing: -0.2px;
  color: #ff7907;
}





.text3{
    font-family: "SFProDisplay";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d1d1d;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .outer{
        margin-top: 0;
        min-height: 500px;

    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .outer{
        margin-top: 0;
        min-height: 500px;

    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .outer{
        margin-top: 0;
        min-height: 500px;

    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .outer{
        min-height: 700px;

    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .outer{
        min-height: 700px;
    }
    .arrow{
        margin-top: 30px;
    }
}