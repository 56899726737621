

.line{
    width: 100%;
    height: 1px;
    border: solid 0.2px rgba(189, 192, 200, 0.6);
  
}


.phone-contact{
  font-family: 'SFProDisplay';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #ff7907;
  text-decoration: none;
}


  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .outer{
        margin-top: 0;
        min-height: 500px;
  
    }

    .address{           
      font-family: "SFProDisplay";
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.23px;
      color: #1d1d1d;      
  }

  .change{
    font-family: "SFProDisplay";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #ff7907;
   float: left;
   margin-left:75%;

}

.price{
  font-size: 14px;
  font-weight: 600;
}

.vendor-card{
  height: 220px;
  width: 300px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}



.title-vendor{
  font-family: "SFProDisplay";
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.35px;
  color: #1d1d1d;
  padding-top: 20%;
  }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .outer{
        margin-top: 0;
        min-height: 500px;
  
    }

    .address{           
      font-family: "SFProDisplay";
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.23px;
      color: #1d1d1d;    
  }

  .vendor-card{
    height: 220px;
    width: 400px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }



  .change{
    font-family: "SFProDisplay";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #ff7907;
    float: left;
    margin-left:75%;

}

.price{
  font-size: 14px;
  font-weight: 600;
}

.title-vendor{
  font-family: "SFProDisplay";
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.35px;
  color: #1d1d1d;
  padding-top: 20%;
  }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .outer{
        margin-top: 0;
        min-height: 500px;
  
    }

    .vendor-card{
      height: 220px;
      width: 480px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  
  
    .change{
      font-family: "SFProDisplay";
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: #ff7907;
      float: left;
      margin-left:75%;
  
  }

    .address{           
      font-family: "SFProDisplay";
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.23px;
      color: #1d1d1d;      
  }

.title-vendor{
  font-family: "SFProDisplay";
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.35px;
  color: #1d1d1d;
  padding-top: 20%;
  }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .outer{
        min-height: 500px;
  
    }
    .addr12{
      margin-left: -25px;
    }

    .address{           
      font-family: "SFProDisplay";
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.23px;
      color: #1d1d1d;      
  }

  .price{
    font-size: 18px;
    font-weight: 600;
  }

  .vendor-card{
    height: 220px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .change{
    font-family: "SFProDisplay";
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #ff7907;
}

.title-vendor{
  font-family: "SFProDisplay";
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.35px;
  color: #1d1d1d;
  padding-top: 20%;
  }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .outer{
        min-height: 500px;
    }
    .arrow{
      margin-top: 30px;
  }

  .change{
    font-family: "SFProDisplay";
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #ff7907;
}

  .address{           
    font-family: "SFProDisplay";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.23px;
    color: #1d1d1d;      
}


.title-vendor{
  font-family: "SFProDisplay";
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.35px;
  color: #1d1d1d;
  padding-top: 20%;
  }

  .addr12{
    margin-left: -25px;
  }
  }