

.typo{
    padding-top: 45%;
    text-align: center;
}

.buttons{
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
}



.phone{
    object-fit: contain;
    padding: 10px;
    padding-left: 80px;
    padding-right: 80px;
    border-radius: 6px;
    background-color: #ff7907;
    border: 1px #ff7907;
    color: #fff;
    margin-top: 4%;
    margin-bottom: 2%;
}

.guest{
    object-fit: contain;
    padding: 10px;
    padding-left: 80px;
    padding-right: 80px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px #fff;
    color: #000;
    margin-top: -2%;
}

.text1{
font-family: "Inter-SemiBold";
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -1.2px;
  text-align: center;
  color: #063021;
}
.text2{
font-size: 20px;
font-family: "SFProDisplay";
  font-weight: normal;
  letter-spacing: -1px;
  color: #063021;
}

.button-success{
    background-color: #ff7907;
    border-color: #ff7907;
  }

.text-botton-3{
    font-family: "Inter-SemiBold";

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .main{
        margin-top: 0;
        min-height: 500px;
        background-color: #f5fcde;
    }
    .text-home{
        font-size: 14px;
    }
   

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .main{
        margin-top: 0;
        min-height: 500px;
        background-color: #f5fcde;
    }

    .text-home{
        font-size: 14px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .main{
        margin-top: 0;
        min-height: 500px;
        background-color: #f5fcde;
    }

    .text-home{
        font-size: 14px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .main{
        min-height: 700px;
        background-image: url('images/group.png');
    background-repeat: no-repeat;
    background-size: cover;
    }
    .arrow{
        margin-top: 30px;
    }

    .text-home{
        font-size: 17px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .main{
        min-height: 700px;
        background-image: url('images/group.png');
    background-repeat: no-repeat;
    background-size: cover;
    }
    .arrow{
        margin-top: 30px;
    }
    .text-home{
        font-size: 17px;
    }

}